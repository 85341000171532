import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routeOptions = [
	{ path: '*', name:'404',  },
    { path: '/', name: 'Home', redirect: '/login' },
    { path: '/dashboard', name: 'Dashboard', layout: "dashboard", meta: { requiresAuth: true } },
    { path: '/categories', name: 'Category', layout: "dashboard", meta: { requiresAuth: true } },
    { path: '/videos', name: 'CategoryVideo', layout: "dashboard", meta: { requiresAuth: true } },
    { path: '/upcoming', name: 'CategoryUpcoming', layout: "dashboard", meta: { requiresAuth: true } },
    { path: '/articles', name: 'CategoryArticle', layout: "dashboard", meta: { requiresAuth: true } },
	{ path: '/revenue-report', name: 'RevenueReport', layout: "dashboard", meta: { requiresAuth: true } },
	{ path: '/block-users', name: 'BlockUser', layout: "dashboard", meta: { requiresAuth: true } },
	{ path: '/user-history', name: 'UserHistory', layout: "dashboard", meta: { requiresAuth: true } },
	{ path: '/campaign-history', name: 'CampaignHistory', layout: "dashboard", meta: { requiresAuth: true } },
    { path: '/login', name: 'Login' },
]

let routes = routeOptions.map(route => {
    return {
        ...route,
        component: () => import(/* webpackChunkName: "[request]" */ `../views/${route.name}.vue`)
    }
})

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute( route, parentLayout = "default" )
{
	route.meta = route.meta || {} ;
	route.meta.layout = route.layout || parentLayout ;
	
	if( route.children )
	{
		route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
	}
	return route ;
}

routes = routes.map( ( route ) => addLayoutToRoute( route ) ) ;

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		if ( to.hash ) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: 'smooth',
		}
	}
})

router.beforeEach((to, from, next) => {
	const auth = JSON.parse(window.localStorage.getItem('auth'));

	if (to.name && (to.name === 'Login') && auth && auth.token) {
		return next('/dashboard');
	}

	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (auth && auth.token) {
			return next()
		}
		return next('/login')
	}
	next();
})

export default router
